import { leafValue } from './object';

/*----Session storage keys------ */
export const SESSION_KEY = {
  MANAGE_USER: {
    FILTER: {
      LOCATION: 'Admin.ManageUser.Filter.Locations',
      TAGS: 'Admin.ManageUser.Filter.Tags',
      TAG_GROUPS: 'Admin.ManageUser.Filter.TagGroups',
      ROLES: 'Admin.ManageUser.Filter.Roles',
      CAREERS: 'Admin.ManageUser.Filter.Careers',
      COACHES: 'Admin.ManageUser.Filter.Coaches',
      INSTANCES: 'Admin.ManageUser.Filter.Instances',
      CREATED_AT_RANGE: 'Admin.ManageUser.Filter.CreatedAtRange',
      PARTNERS: 'Admin.ManageUser.Filter.Partners',
    },
    SEARCH_TERM: 'Admin.ManageUser.SearchTerm',
    SELECTED_PROFILES: 'Admin.ManageUser.SelectedProfiles',
  },
  RECRUITER: {
    MANUAL_JOB: {
      EXPIRY_DATE: 'Recruiter.ManualJob.ExpiryDate',
    },
  },
};

/**
 * Remove any sessionStorage filter items that are set in a Coach/ Admin session in Manage Users table.
 */
export const removeManageUsersFilterSessionItems = () => {
  leafValue(SESSION_KEY).forEach((key: string) => {
    window.sessionStorage.removeItem(key);
  });
};
